import blobStream from 'blob-stream';
import { generateInvoiceExcel } from '../../../api/IndexApi';
import { getCurrencyValue, getInvoiceCalculations } from '../../../helpers/DataHelpers';
import { Invoice } from '../../../interfaces/invoices/InvoiceTypes';

interface FileDictionary {
    [key: string]:{
        url: string,
        data?: any,
        xhr?: XMLHttpRequest
    }
}

const styles = {
    header: { 
        width: 500,
        align: "left",

    }
}

export class InvoiceAssetGenerator {
    async generateAsset(rowObject: Invoice, inProgress: boolean) {
        try {
            let formatName = rowObject.job?.customer?.printFormatInvoice?.name ?? "Default"
            if (formatName === "Default") {
                this.generateInvoicePdf(rowObject, inProgress)
            } else {
                await generateInvoiceExcel(rowObject.id, inProgress)
            }
        } catch {
            this.generateInvoicePdf(rowObject, inProgress)
        }
    }

    async generateInvoicePdf(rowObject: Invoice, inProgress: boolean) {
        const customerName = rowObject.job.customer ? rowObject.job.customer.name : "Customer Name"
        const customerAddress = rowObject.job.customer ? rowObject.job.customer.address : "Customer Address"

        return new Promise((resolve) => {
            var filesLoaded = 0;
        
            var files: FileDictionary = {
                img1: {
                    url: `${window.location.origin}/TruFormHeader.png`,
                },
                img2: {
                    url: `${window.location.origin}/TruFormFooter.png`,
                },
                img3: {
                    url: `${window.location.origin}/signature.png`,
                },
                img4: {
                    url: `${window.location.origin}/invoice_second_page.png`,
                },
                img5: {
                    url: `${window.location.origin}/invoice_pdf_final.png`,
                }
            };
    
            //@ts-ignore
            const doc = new PDFDocument({
                bufferPages: true,
                margins: {
                    top: 36,
                    bottom: 36,
                    left: 36,
                    right: 36            
                }
            });        
            const stream = doc.pipe(blobStream());
    
            function loadedFile(xhr:XMLHttpRequest) {
                for (var file in files) {
                    if (files[file].url === xhr.responseURL) {
                        files[file].data = xhr.response;
                    }
                }
                filesLoaded += 1;
                if (filesLoaded === Object.keys(files).length) {
                    doc.switchToPage(0)
                    doc.image(files.img1.data, 24, 40, {fit: [572, 38]});
                    doc.image(files.img2.data, 20, 705, {fit: [572, 19]});
                    /*doc.text("________________________________", 75, 550, {fit: [150, 150]})
                        .moveDown(.5)
                        .text("Dan Ruppe")
                        .moveDown(.5)
                        .text("President")
                    */
                    doc.switchToPage(1)
                    
                    // doc.image(files.img4.data, 50, 120, {fit: [500, 600]});
                    doc.font('Helvetica-Bold')
                        .text("",50, 120, styles.header)
                        .text("                                 CONDITIONAL WAIVER AND RELEASE UPON " 
                            + (inProgress ?
                                "PROGRESS PAYMENT" :
                                "FINAL PAYMENT")).moveDown(0.5)
                        .text("                                                                     California Civil Code §8136 (3)").moveDown(0.5)
                        .text("").moveDown(0.5)
                        .font('Helvetica')
                        .text("NOTICE THIS DOCUMENT WAIVES THE CLAMANT’S LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS EFFECTIVE ON RECEIPT OF PAYMENT. A PERSON SHOULD NOT RELY ON THIS DOCUMENT UNLESS SATISFIED THAT THE CLAIMANT HAS RECEIVED PAYMENT").moveDown(0.5)
                        .text("").moveDown(0.5)
                        .font('Helvetica-Bold')
                        .moveDown(1)
                        .text("                                                                      Identifying Information").moveDown(0.5)
                        .text("Name of Claimant:__________________________").moveDown(0.5)
                        .text("Name of Customer:__________________________").moveDown(0.5)
                        .text("Job Location:______________________________").moveDown(0.5)
                        .text("Owner:_____________________________________").moveDown(0.5)
                        .text("Through Date:______________________________").moveDown(0.5)
                        .text("").moveDown(0.5)
                        .text("                                                                 Conditional Waiver and Release").moveDown(0.5)
                        .text("").moveDown(0.5)
                        .font('Helvetica')
                        .text("This document waives and releases lien, stop payment notice, and payment bond rights the claimant has for ").moveDown(0.5)
                        .text("labor and service provided, and equipment and material delivered, to the customer on this job. Rights based ").moveDown(0.5)
                        .text("upon labor or service provided, or equipment or material delivered, pursuant to a written change order that ").moveDown(0.5)
                        .text("has been fully executed by the parties prior to the date that this document is signed by the claimant, are ").moveDown(0.5)
                        .text("waived and released by this document, unless listed as an Exception below. This document is effective only ").moveDown(0.5)
                        .text("on the claimants recipe of payment form the financial institution on which the following check is drawn:").moveDown(0.5)
                        .text("").moveDown(0.5)
                        .font('Helvetica-Bold')
                        .text("Maker of Check:______________").moveDown(0.5)
                        .text("Amount of Check:______________").moveDown(0.5)
                        .text("Check Payable to:______________").moveDown(0.5)
                        .text("").moveDown(0.5)
                        .text("                                                                                 Exceptions").moveDown(0.5)
                        if (inProgress) {
                            doc.text("This document does not affect any of the following:").moveDown(0.5)
                                .text("1. Retentions.").moveDown(0.5)
                                .text("2. Extras for which the claimant has not recieved payment.").moveDown(0.5)
                                .text("3. The following progress payments for which the claimant has previously, given a conditional waiver").moveDown(0.5)
                                .text("and release but has not received payment:").moveDown(0.5)
                                .text("        Date(s) of waiver and release:___________________________").moveDown(0.5)
                                .text("        Amount(s) of unpaid progress payment(s):").moveDown(0.5)
                                .text("4. Contract rights, including (A) a right based rescission, abandonment, or breach of contract, and").moveDown(0.5)
                                .text("        (B) the right to recover compensation for work not compensated by the payment.").moveDown(0.5)
                                .moveDown(1)
                        } else {
                            doc.text("This document does not affect any of the following:")
                                .moveDown(0.5)
                                .text("Disputed claims for extras in the amount of:")
                                .moveDown(11.5)
                        }
                        doc.text("Claimant's Signature:_____________")
                        .text("Claimant's Title:_________________")
                        .text("Date of Signature:________________")

                    // Invoice Values 
                    doc.font('Helvetica')
                        .text("", 160, 235, { width: 200, align: "left" })
                        .text("TruForm Construction Corporation")
                        .moveDown(.5)
                        .text(customerName  || " ")
                        .moveDown(.5)
                        .text(rowObject.job.address || " ")
                        .moveDown(.5)
                        .text(rowObject.job.owner || " ")
                        .moveDown(.5)
                        .text(new Date(rowObject.billThroughDate || " ").toDateString())

                    doc.text("", 150, 462, { width: 200, align: "left" })
                        .text(customerName || " ")
                        .moveDown(.5)
                        .text(getCurrencyValue(calculations.totalDueThisStatement))
                        .moveDown(.5)
                        .text("TruForm Construction Corporation")

                    doc.text("", 155, inProgress ? 730 : 721, { width: 200, align: "left" })
                        .text("President")
                        .text(new Date().toDateString() || " ")    
                    doc.end()
                }
            }
    
            for (var file in files) {
                files[file].xhr = new XMLHttpRequest();
                files[file].xhr!.onreadystatechange = function() {
                    if (this.readyState === 4 && this.status === 200) {
                        loadedFile(this);
                    }     
                };
                files[file].xhr!.responseType = 'arraybuffer';
                files[file].xhr!.open('GET', files[file].url);
                files[file].xhr!.send(null); 
            }        
            
            doc.font('Helvetica-Bold');
            doc.fontSize(10);
    
            // Center Headers
            doc.text('Job #', 200, 123, {align: 'right', width: 75})
                .moveDown(0.5)
                .text('Contract #', {align: 'right', width: 75})
                .moveDown(0.5)
                .text('Reference #', {align: 'right', width: 75})
                .moveDown(0.5)
                .text('Invoice #', {align: 'right', width: 75})
                .moveDown(0.5)
                .text('Billing Through', {align: 'right', width: 75})
    
            // Center Values
            doc.font('Helvetica-Bold');
            doc.fontSize(15);
            if (!inProgress) {
                doc.text("RETENTION", 245, 100, { height: 23, width: 120, align: 'center' })
                doc.underline(260, 105, 88, 10, {color: 'black'})
            }

            doc.fontSize(10);
            doc.font('Helvetica');
            doc.text(rowObject.job.id, 280, 123, { width: 120 })
                .moveDown(0.5)    
                .text(rowObject.contractNumber || " ")
                .moveDown(0.5)
                .text("---")
                .moveDown(0.7)
                .text(rowObject.invoiceNumber || " ")
                .moveDown(0.5)
                .text(new Date(rowObject.billThroughDate || " ").toDateString())
    
            // Left Values
            doc.font('Helvetica-Bold');
            doc.text(customerName, 40, 123, { width: 132 })
                .moveDown(0.5)    
                .text(customerAddress, { width: 132 })
    
            // Right Values
            doc.font('Helvetica-Bold');
            doc.text(rowObject.job.name, 440, 123, { width: 132 })
                .moveDown(0.5)    
                .text(rowObject.job.address, { width: 132 })
    
            // Cost Headers
            doc.font('Helvetica-Bold');
            doc.text('Contract Amount', 100, 250, {align: 'left', width: 150})
                .moveDown(0.9)
                .text('Change Orders to Date', {align: 'left', width: 150})
                .moveDown(0.9)
                .text('Revised Contract Amount', {align: 'left', width: 150})
                .moveDown(0.9)
                .text('Percentage Complete To Date', {align: 'left', width: 150})
                .moveDown(0.9)
                .text('Less Retention', {align: 'left', width: 150})
                .moveDown(0.9)
                .text('Total This Statement', {align: 'left', width: 150})
                .moveDown(0.9)
                .text('Less Previously Billed', {align: 'left', width: 150})
                .moveDown(1.3)
                .text('Total Due This Statement', {align: 'right', width: 250})
    
            doc.font('Helvetica');
            let calculations = getInvoiceCalculations(rowObject)
            doc.text(getCurrencyValue(rowObject.contractAmount ?? 0), 350, 250, {align: 'right', width: 150})
                .moveDown(0.9)
                .text(getCurrencyValue(calculations.changeOrdersToDate), {align: 'right', width: 150})
                .moveDown(1.2)
                .text(getCurrencyValue(calculations.revisedContractAmount), {align: 'right', width: 150})
                .moveDown(0.9)
                .text(getCurrencyValue(calculations.amountCompletedToDate), {align: 'right', width: 150})
                .moveDown(0.9)
                .text(getCurrencyValue(calculations.lessRetentionAmount), {align: 'right', width: 150})
                .moveDown(0.9)
                .text(getCurrencyValue(calculations.totalThisStatement), {align: 'right', width: 150})
                .moveDown(0.9)
                .text(getCurrencyValue(rowObject.lessPreviouslyBilled ?? 0), {align: 'right', width: 150})
                .moveDown(1.3)
                .text(getCurrencyValue(calculations.totalDueThisStatement), {align: 'right', width: 150})
    
            // total due this statement line
            doc.moveTo(425, 425)
                .lineTo(500, 425)
                .stroke()

            // through change order number
            if (rowObject.throughTrufChangeOrderNumber) {
                doc.font('Helvetica-Bold');
                doc.text(`Through TFC CO #${rowObject.throughTrufChangeOrderNumber}`, 250, 272, {align: 'right', width: 150})
            }
    
            // add percentage values
            doc.font('Helvetica-Bold');
            doc.text(`${rowObject.percentageCompletedToDate ?? 0} %`, 250, 319, {align: 'right', width: 150})
                .moveDown(0.9)
                .text(`${rowObject.lessRetentionPercentage ?? 0} %`, {align: 'right', width: 150})
    
            // add date of invoice
            doc.font('Helvetica-Bold');
            doc.text("DATE OF INVOICE", 300, 550, {align: 'left', width: 100})
            doc.font('Helvetica');
            doc.text(new Date().toDateString(), 430, 550, {align: 'left', width: 150})
    
            // NEW PAGE
            doc.addPage();
    
            doc.fontSize(12);
    
            doc.font('Helvetica-Bold');
            doc.text(customerName, 50, 50, { width: 132 })
                .moveDown(0.5)    
                .text(customerAddress, { width: 132 })
    
            doc.font('Helvetica-Bold');
            doc.text(`CONTRACT NO: ${rowObject.contractNumber ?? "---"}`, 350, 50, { width: 200, align: "right" })
                .moveDown(0.5)    
                .text(`INVOICE NO: ${rowObject.invoiceNumber ?? "---"}`, { width: 200, align: "right" })
    
            doc.font('Helvetica');
            doc.fontSize(10);
    
            stream.on('finish', function() {
                const blob = stream.toBlob('application/pdf');
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                resolve(true)
            });
        })
    }
}
